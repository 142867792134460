<template>
    <div class="rewardsBox">
        <header class="head">{{ $t('valentine_tab1_romantictrip') }}</header>
        <div class="tableBox">
            <p class="tableHead">
                <span>{{ $t('valentine_rewards_romantictrip_title1') }}</span>
                <span>{{ $t('valentine_rewards_romantictrip_title2') }}</span>
            </p>
            <ul class="tableBody">
                <li>
                    <div>{{ $t('valentine_trip_step1') }}</div>
                    <div class="tableBodyRight">
                        <div style="padding:0 10px;">
                            <img src="../../assets/cpEvent/level1.png" alt="">
                            <span class="giftNameBox"> 
                                <span>{{ $t('valentine_frame1') }}</span>
                                <span>{{ $t('valentine_7day') }}</span>
                                <span>{{ $t('valentine_reward_male')}}</span>
                            </span>
                        </div>
                        <div>
                            <img src="../../assets/cpEvent/level1_1.png" alt="">
                            <span class="giftNameBox">
                                <span>{{ $t('valentine_frame1_female') }}</span>
                                <span>{{ $t('valentine_7day') }}</span>
                                <span>{{ $t('valentine_reward_female')}}</span>
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div>{{ $t('valentine_trip_step2') }}</div>
                    <div class="tableBodyRight">
                        <div>
                            <img src="../../assets/cpEvent/level2.png" alt="">
                            <span class="giftNameBox">
                                <span>{{ $t('valentine_chatbubble') }}</span>
                                <span>{{ $t('valentine_15day') }}</span>
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div>{{ $t('valentine_trip_step3') }}</div>
                    <div class="tableBodyRight">
                        <div>
                            <img src="../../assets/cpEvent/level3.png" alt="">
                            <span class="giftNameBox">
                                <span>{{ $t('valentine_entranceeffect') }}</span>
                                <span>{{ $t('valentine_20day') }}</span>
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div>{{ $t('valentine_trip_step4') }}</div>
                    <div class="tableBodyRight">
                        <div style="padding:0 10px;">
                            <img src="../../assets/cpEvent/level4.png" alt="">
                            <span class="giftNameBox">
                                <span>{{ $t('valentine_frame2') }}</span>
                                <span>{{ $t('valentine_20day') }}</span>
                                <span>{{ $t('valentine_reward_male')}}</span>
                            </span>
                        </div>
                        <div>
                            <img src="../../assets/cpEvent/level4_1.png" alt="">
                            <span class="giftNameBox">
                                <span>{{ $t('valentine_frame2_female') }}</span>
                                <span>{{ $t('valentine_20day') }}</span>
                                <span>{{ $t('valentine_reward_female')}}</span>
                            </span>
                        </div>
                    </div>
                </li>
                <li>
                    <div>{{ $t('valentine_trip_step5') }}</div>
                    <div class="tableBodyRight oneLineBox">
                        <div class="oneLine">
                            <div>
                                <img src="../../assets/cpEvent/level5All.png" alt="">
                                <span class="giftNameBox">{{ $t('valentine_cpidentification') }}</span>
                            </div>
                            <div>
                                <img class="level5rules" src="../../assets/cpEvent/level5rules.png" alt="">
                                <span class="giftNameBox">{{ $t('valentine_cpwall') }}</span>
                            </div>
                        </div>
                        <div class="tableTips">{{ $t('valentine_reward_send') }}</div>
                    </div>
                </li>
            </ul>
        </div>
        <header class="head">{{ $t('valentine_tab3') }}</header>
        <div class="rewardsText">
            <p>{{ $t('valentine_dailycp_reward') }}</p>
            <div class="dailyImgBox">
                <div>
                    <img src="../../assets/cpEvent/daily1.png" alt="">
                    <span>{{ $t('valentine_dailycp_maleuser') }}</span>
                </div>
                <div>
                    <img src="../../assets/cpEvent/daily2.png" alt="">
                    <span>{{ $t('valentine_dailycp_femaleuser') }}</span>
                </div>
            </div>
        </div>
        <header class="head">{{ $t('valentine_tab4') }}</header>
        <div class="tableBox">
            <p class="tableHead tableHead2">
                <span>{{ $t('valentine_overall_rank') }}</span>
                <span>{{ $t('valentine_overall_dollar') }}</span>
                <span>{{ $t('valentine_overall_roombg') }}</span>
            </p>
            <!-- 区分大区 -->
            <!-- 西语葡语 -->
            <ul class="tableBody tableBody2 tableBodyChange1" v-if="regionName == 'pt' || regionName == 'es'">
                <li>
                    <div>{{ $t('valentine_overall_top1') }}</div>
                    <div>$ 250</div>
                    <div></div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_top2') }}</div>
                    <div>$ 150</div>
                    <div></div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_top3') }}</div>
                    <div>$ 50</div>
                    <div></div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_top4-10') }}</div>
                    <div>-</div>
                    <div></div>
                </li>
            </ul>
            <!-- 土语 -->
            <ul class="tableBody tableBody2 tableBodyChange1" v-else-if="regionName == 'tr'">
                <li>
                    <div>{{ $t('valentine_overall_top1') }}</div>
                    <div>$ 400</div>
                    <div></div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_top2') }}</div>
                    <div>$ 300</div>
                    <div></div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_top3') }}</div>
                    <div>$ 100</div>
                    <div></div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_top4-10') }}</div>
                    <div>-</div>
                    <div></div>
                </li>
            </ul>
            <!-- 阿语 -->
            <ul class="tableBody tableBody2 tableBodyChange1" v-else>
                <li>
                    <div>{{ $t('valentine_overall_top1') }}</div>
                    <div>$ 500</div>
                    <div></div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_top2') }}</div>
                    <div>$ 300</div>
                    <div></div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_top3') }}</div>
                    <div>$ 200</div>
                    <div></div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_top4-10') }}</div>
                    <div>-</div>
                    <div></div>
                </li>
            </ul>
        </div>
        <div class="rewardsText">{{ $t('valentine_overall_supporter') }}</div>
        <div class="tableBox">
            <p class="tableHead tableHead2 tableHead3">
                <span>{{ $t('valentine_overall_rank') }}</span>
                <span v-if="regionName == 'pt' || regionName == 'es'">{{ $t('valentine_overall_backpackgift_espt') }}</span>
                <span v-else>{{ $t('valentine_overall_backpackgift_artr') }}</span>
                <span>{{ $t('valentine_overall_giftname') }}</span>
            </p>
            <!-- 区分大区 -->
            <!-- 西语葡语 -->
            <ul class="tableBody tableBody2 tableBody3" v-if="regionName == 'pt' || regionName == 'es'">
                <li>
                    <div>{{ $t('valentine_overall_supporter1') }}</div>
                    <div>*3</div>
                    <div>{{$t('valentine_10days')}}-</div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_supporter2') }}</div>
                    <div>*2</div>
                    <div>-</div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_supporter3') }}</div>
                    <div>*1</div>
                    <div>-</div>
                </li>
            </ul>
            <!-- 土语 -->
            <ul class="tableBody tableBody2 tableBody3" v-else-if="regionName == 'tr'">
                <li>
                    <div>{{ $t('valentine_overall_supporter1') }}</div>
                    <div>*3</div>
                    <div>{{$t('valentine_10days')}}-</div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_supporter2') }}</div>
                    <div>*2</div>
                    <div>-</div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_supporter3') }}</div>
                    <div>*1</div>
                    <div>-</div>
                </li>
            </ul>
            <!-- 阿语 -->
            <ul class="tableBody tableBody2 tableBody3" v-else>
                <li>
                    <div>{{ $t('valentine_overall_supporter1') }}</div>
                    <div>*5</div>
                    <div>{{$t('valentine_10days')}}-</div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_supporter2') }}</div>
                    <div>*3</div>
                    <div>-</div>
                </li>
                <li>
                    <div>{{ $t('valentine_overall_supporter3') }}</div>
                    <div>*2</div>
                    <div>-</div>
                </li>
            </ul>
        </div>
    </div>
   
  </template>
  
  <script>
  export default {
    name: "rewards",
    props:['region'],
    data() {
      return{
        regionName:'ar'
      }
    },
    created() { 
      //调用原生方法
      this.$completes.complete("getUserInfo");
      //原生调用h5方法
      window.getUserInfo = this.getUserInfo;
      window["getUserInfo"] = (data) => {
        this.getUserInfo(data);
      };
        // 葡语
        if(this.region == 108){
            this.regionName = 'pt';
            // 西语
        }else if(this.region == 107 || this.region == 20011){
            this.regionName = 'es';
            // 土语
        }else if(this.region == 102 || this.region == 102){
            this.regionName = 'tr';
            // 中东兜底
        }else{
            this.regionName = 'ar';
        }
  
    //   this.getUserInfo() 

    },
    computed:{
    },
    methods: {
      getUserInfo(data) {
        this.userInfo = JSON.parse(data);
        this.token = JSON.parse(data).token
        this.uid = JSON.parse(data).userId
        let langs = this.userInfo.lang.substring(0,2)
        this.langs = this.userInfo.lang.substring(0,2)
        let lang = this.userInfo.lang
        this.headers = {
            fid:  this.userInfo.fid,
            os:  this.userInfo.os || 1,
            lang: this.userInfo.lang, 
            version:this.userInfo.version,
            deviceid:this.userInfo.deviceId,
          }
    //   this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt6TWpJNCIsInh5eiI6IlEwWkRRakJCTURBdFJEYzJOaTAwTURZMkxUaEJRamd0UkVaR01rVXhSRFpCUWpjMSIsImV4cCI6MTk5MjkzODIyNn0.-8-fxCL-8HULEkCIUdI9AaLZEBYdDwi9ZHC3cjhxa5Y';
    //     this.uid = '793228'; 
    //     this.headers = {
    //         fid: '1001',
    //         os: 1,
    //         lang: 'zh-CN',
    //         version: '3.0',
    //         deviceid: 'CFCB0A00-D766-4066-8AB8-DFF2E1D6AB75',
    //     }
    //     let langs = 'en'
    //     this.langs = 'en'
    //     let lang = 'zh_CN'
      document.title = this.$t('valentine_title')
        if (langs == 'en') {
          this.$i18n.locale = 'en'
        }
        else if (langs == 'ar') {
          this.$i18n.locale = 'ar'
        }
        else if (langs == 'tr') {
          this.$i18n.locale = 'tr'
        }
        else if (langs == 'pt') {
          this.$i18n.locale = 'pt'
        }
        else if (langs == 'es') {
          this.$i18n.locale = 'es'
        }
        else {
          this.$i18n.locale = 'en'
        }
        document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      },
      
    }
  };
  </script>
  
  <style scoped>
  html[lang="ar"] .tableBody li>div:nth-of-type(1){
    border-right: none;
    border-left: 1px solid #c1c1c1;
  }
  html[lang="ar"] .tableBody2 li div:nth-child(2){
    border-right: none;
  }
  p{
    margin: 0;
  }
  .rewardsBox{
    width: 100%;
    height: 95%;
    overflow: scroll;
    scrollbar-width: none;
    font-family: 'Montserrat';
    padding-bottom: 30px;
    /* margin-top: -10px; */
  }
  .rewardsBox header{
    font-size: 28px;
    line-height: 34px;
    color: #000;
    margin: 50px auto 0;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rewardsBox header:nth-of-type(1){
    margin-top: 10px;
  }
  .head::before{
    content: '';
    width: 26px;
    height: 6px;
    background: #000;
    margin-right: 12px;
  }
  .head::after{
    content: '';
    width: 26px;
    height: 6px;
    background: #000;
    margin-left: 12px;
  }
  .tableBox{
    border: 1px solid #c1c1c1;
    width: 600px;
    border-radius: 24px;
    margin: 20px auto 0;
  }
  .tableHead{
    height: 80px;
    display: flex;
    border-bottom: 1px solid #c1c1c1;
  }
  .tableHead span{
    font-size: 20px;
    font-weight: 400;
    line-height: 80px;
    text-align: center;
    color: #000;
  }
  .tableHead span:nth-of-type(1){
    width: 200px;
  }
  .tableHead span:nth-of-type(2 ){
    width: 400px;
  }
  .tableBody li{
    display: flex;
    border-bottom: 1px solid #c1c1c1;
  }
  .tableBody li:nth-last-child(1){
    border-bottom: none;
  }
  .tableBody li>div:nth-of-type(1){
    width: 200px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    text-align: center;
    padding: 60px 20px;
    box-sizing: border-box;
    border-right: 1px solid #c1c1c1;
  }
  .tableBody li>div:nth-of-type(2){
    width: 370px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-top: 10px;
  }
  .tableBodyRight{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tableBodyRight img{
    display: block;
    width: 88px;
    height: 88px;
    margin: 0 auto;
  }
  .tableBodyRight span{
    display: block;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
    text-align: center;
  }
  .rewardsText{
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    color: #000;
    padding: 0 20px;
    /* word-break: break-all; */
    margin-top: 24px;
  }
  .tableHead2{
    height: 110px;
    align-items: center;
  }
  .tableHead2 span:nth-of-type(1){
    width: 218px;
  }
  .tableHead2 span:nth-of-type(2){
    width: 178px;
  }
  .tableHead2 span:nth-of-type(3){
    width: 204px;
    line-height: 22px;
  } 
  .tableBody2 li div{
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
  }
  .tableBody2 li div:nth-child(1){
    width: 218px;
    padding: 0;
  }
  .tableBody2 li div:nth-child(2){
    width: 178px;
    border-right: 1px solid #c1c1c1;
    padding-left: 0;
  }
  .tableBody2 li div:nth-child(3){
    width: 204px;
  }
  .tableHead3 span:nth-of-type(2){
    line-height: 22px;
  }
  .tableHead3 span:nth-of-type(3){
    padding: 0 20px;
    box-sizing: border-box;
  }
  .tableTips{
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #CED4DD;
    text-align: center;
    margin-top: 10px;
  }
  .oneLineBox{
    display: block;
  }
  .oneLine{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 144px; */
  }
  .dailyImgBox{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .dailyImgBox img{
    display: block;
    width: 180px;
    height: 180px;
    margin: 0 32px;
  }
  .dailyImgBox span{
    display: block;
    width: 150px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: #000;
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
  }
  .tableBodyChange1 li div:nth-of-type(3){
    width: 178px;
    height: 48px;
    background: url('../../assets/cpEvent/rulesChange1.png') no-repeat;
    background-size: 100% 100%;
    margin: 10px auto 0;
    
}
.giftNameBox{
    width: 200px;
}
.tableBodyRight .level5rules{
    width: 128px;
    object-fit: contain;
}
  </style>
  